
class DOMUtilities {
    public static isDisabled(elem: HTMLElement): boolean {
        return (elem.getAttribute("disabled") != null);
    }

    public static isFunction(obj: any): boolean {
        return (typeof obj === "function");
    }

    public static hide(el: HTMLElement): void {
        if (el && el.style) {
            el.style.display = "none";
        }
    }

    public static show(el: HTMLElement): void {
        if (el && el.style) {
            el.style.display = "";
        }
    }

    public static closest(elem: HTMLElement, selector: string): HTMLElement {
        //start with elem
        var parent: any = elem;

        //use the browser-supplied matches function, or fallback to a polyfill
        var p: any = Element.prototype;
        var f: any = p.matches ||
            p.webkitMatchesSelector ||
            p.mozMatchesSelector ||
            p.msMatchesSelector ||
            function (s) {
                return [].indexOf.call(document.querySelectorAll(s), this) !== -1;
            };

        while (parent) {
            if (f.call(parent, selector)) {
                return parent;
            }

            parent = parent.parentElement;
        }
        return null;
    }

    public static selectorEvent(elem: HTMLElement, selector: string, handler: any) {
        return function (event: UIEvent): any {

            var target: EventTarget = event.target;

            var base: any = elem;

            var nodeList: NodeList = base.querySelectorAll(selector);

            if (nodeList.length == 0 && base.shadowRoot) {
                base = base.shadowRoot;
                nodeList = base.querySelectorAll(selector);
            }

            for (var i = 0; i < nodeList.length; ++i) {
                var node: Node = nodeList[i];

                //DOCUMENT_POSITION_CONTAINED_BY = 16
                if (target == node || (base.compareDocumentPosition(node) & 16) == 16 || base.contains(node)) {
                    var e: any = event;
                    // set our own property so handler can know what real target is
                    e.selectorTarget = node;
                    return handler.call(this, event);
                }
            }
        }
    }

    public static isNumeric(value: any): boolean {
        //var result: number = parseInt(value);
        //return isNaN(result) == false;

        return !isNaN(parseFloat(value)) && isFinite(value);
    }

    public static addClass(list: NodeList, cssClass: string): void {
        for (var x: number = 0; x < list.length; ++x) {
            var item: HTMLElement = <HTMLElement>list[x];
            item.classList.add(cssClass);
        }
    }

    public static removeClass(list: NodeList, cssClass: string): void {
        for (var x: number = 0; x < list.length; ++x) {
            var item: HTMLElement = <HTMLElement>list[x];
            item.classList.remove(cssClass);
        }
    }

    public static append(parent: HTMLElement, nodeList: Array<Node>): void {

        for (var x: number = 0; x < nodeList.length; ++x) {
            parent.appendChild(nodeList[x]);
        }
    }

    public static removeChildren(elem: HTMLElement, selector: string) {
        var nodeList: NodeList = elem.querySelectorAll(selector);
        for (var x: number = 0; x < nodeList.length; ++x) {
            var node: Node = nodeList[x];
            node.parentNode.removeChild(node);
        }
    }

    public static height(el: HTMLElement, height?: number): number {
        var h: number = (height ? height : 0);

        if (!height) {
            h = el.clientHeight;
        } else {
            el.style.height = height + "px";
        }

        return h;
    }

    public static toBoolean(value: string): boolean {
        var ret: boolean = false;
        if (typeof value === "boolean") {
            value = "" + value;
        }

        if (value) {

            value = value.toLowerCase();
            ret = value === "true";
        }


        return ret;
    }

    public static width(el: HTMLElement, width?: number): number {

        var w: number = (width ? width : 0);
        if (el) {
            if (!width) {
                w = el.clientWidth;
            } else {
                el.style.width = width + "px";
            }
        }
        return w;
    }


    public static outerHeight(el: HTMLElement, includeMargin: boolean): number {
        var height: number = el.offsetHeight || el.clientHeight;

        if (includeMargin) {
            var style: CSSStyleDeclaration = getComputedStyle(el);

            height += parseInt(style.marginTop) + parseInt(style.marginBottom);
        }
        return height;
    }

    public static outerWidth(el: HTMLElement, includeMargin: boolean): number {
        var width: number = el.offsetWidth;

        if (width === 0) {
            var w: number = parseInt(el.style.width);
            if (!isNaN(w)) {
                width = w;
            }
        }

        if (includeMargin) {
            var style: CSSStyleDeclaration = getComputedStyle(el);

            width += parseInt(style.marginLeft) + parseInt(style.marginRight);
        }
        return width;
    }


    public static formatString(item, format, args) {
        var arr = args.split(",");
        var formatted = format;
        for (var i = 0; i < arr.length; i++) {
            var regexp = new RegExp('\\{' + i + '\\}', 'gi');
            var property = arr[i].trim();


            formatted = formatted.replace(regexp, item[property]);
        }
        return formatted;
    }

    public static isVisible(el: HTMLElement): boolean {
        return el.style.display !== "none";
    }

    public static round(value, precision) {
        var power = Math.pow(10, precision || 0);
        return (Math.round(value * power) / power).toFixed(precision);
    };

    public static getChar(event: KeyboardEvent): string {
        // event.type must be keypress
        if (event.which === null) {
            return String.fromCharCode(event.keyCode) // IE
        } else if (event.which !== 0 && event.charCode !== 0) {
            return String.fromCharCode(event.which)   // the rest
        } else {
            return null // special key
        }
    }

    public static isNumberValid(ctrl: HTMLInputElement, newChar: string, decimals?: number) {
        var ret = true;

        //get the current insertion point
        var selection = this._caret(ctrl);
        var selectionStart = selection[0];
        var selectionEnd = selection[1];

        //and the current value
        var value = ctrl.value;
         

        //build the prospective value
        value = value.substring(0, selectionStart) + newChar + value.substring(selectionEnd);

        //if we don't have a number at all, return false
        var separator = "\\.";
        var decimalPlaceRegEx = "?";
        var _numericRegExp = null;

        //var decimals = $(ctrl).attr("data-decimals");
        //if (this.decimals && $.isNumeric(decimals)) {
        if (decimals === undefined) {
            decimals = parseFloat(ctrl.getAttribute("data-decimals"));
        }

        if (decimals) {
            decimalPlaceRegEx = "{0," + decimals.toString() + "}";
            if (0 == decimals) {
                _numericRegExp = new RegExp("/^(-)?(\d*)$/");
            }
        }

        if (null == _numericRegExp) {
            _numericRegExp = new RegExp("^-?\\d*" + separator + "?\\d" + decimalPlaceRegEx + "$");
        }

        ret = _numericRegExp.test(value);

        //this doesn't work since you may not be able to type a number at ll since it could be below the lower bound
        //if (ret) {
        //    //if we have a number, see if it fits our criteria
        //    ret = _isWithinBound(ctrl, value, "lowerbound").ret;

        //    //only proceed if we have not already failed the validation
        //    if (ret) {
        //        ret = _isWithinBound(ctrl, value, "upperbound").ret;
        //    }
        //}

        return ret;
    }
      

    public static isNumberValid2(ctrl: HTMLInputElement, newChar: string, newValue: string, decimals: number,
        max: number, min: number, wholeDigits: number) {

        let ret = true;

        //get the current insertion point
        const selection = this._caret(ctrl);
        const selectionStart = selection[0];
        const selectionEnd = selection[1];

        //and the current value
        let value: string;

        if (newValue !== undefined && newValue !== null) {

            value = newValue;
        } else {

            value = ctrl.value;

            //build the prospective value
            value = value.substring(0, selectionStart) + newChar + value.substring(selectionEnd);
        }

        //var decimals = $(ctrl).attr("data-decimals");
        //if (this.decimals && $.isNumeric(decimals)) {
        //if (decimals === undefined || decimals === null) {
        //    decimals = parseFloat(ctrl.getAttribute("data-decimals"));
        //}

        //if (max === undefined || max === null) {
        //    max = parseFloat(ctrl.getAttribute("max"));
        //}

        //if (min === undefined || min === null) {
        //    min = parseFloat(ctrl.getAttribute("min"));
        //}

        //if (wholeDigits === undefined || wholeDigits === null) {
        //    wholeDigits = parseFloat(ctrl.getAttribute("whole-digits"));
        //}

        let numericRegExp = null;
        let decimalsRegEx = "";
        const negativeOperator = min !== undefined && min !== null && min === 0 ? "" : "-?";
        const separatorChar = ".";
        const separator = `\\${separatorChar}`;
        let wholeDigitsRegEx = `${negativeOperator}\\d*`;

        // Allow a decimal point (period) without anything else so the User can type
        //  .99 or something like that.
        if ((decimals ?? 0) > 0 && newChar === separatorChar) {

            if ((value.split(separatorChar).length - 1) === 1) {
                ret = true;
            } else {
                ret = false;
            }
        } else {

            if (wholeDigits !== undefined && wholeDigits !== null && wholeDigits >= 0) {

                wholeDigitsRegEx = `${negativeOperator}\\d{0,${wholeDigits}}`;
            }

            if (decimals !== undefined && decimals !== null && decimals > 0 && value.includes(separatorChar)) {

                decimalsRegEx = `${separator}\\d{0,${decimals}}`;
            }

            const regExString = `^${wholeDigitsRegEx}${decimalsRegEx}$`;

            if (numericRegExp === null) {
                numericRegExp = new RegExp(regExString);
            }

            ret = numericRegExp.test(value);
        }

        if (this.isNumeric(value) === true) {

            const valueNumber: number = parseFloat(value);

            if (ret === true && min !== undefined && min !== null && valueNumber < min) {
                ret = false;
            }

            if (ret === true && max !== undefined && max !== null && valueNumber > max) {
                ret = false;
            }
        }

        //this doesn't work since you may not be able to type a number at all since it could be below the lower bound
        //if (ret) {
        //    //if we have a number, see if it fits our criteria
        //    ret = _isWithinBound(ctrl, value, "lowerbound").ret;

        //    //only proceed if we have not already failed the validation
        //    if (ret) {
        //        ret = _isWithinBound(ctrl, value, "upperbound").ret;
        //    }
        //}

        return ret;
    }


    // *** Rivets would not change the object model when the element checked was changed.

    ///// Only allow one checkbox to be checked for the check boxes in the group. Multiple groups are allowed to be space separated.
    ///// <input type="checkbox" data-chk-group="userDefault1 userDefault2">
    //public static mutuallyExclusiveCheckbox(activeElement: HTMLInputElement, dataAttribute: string) {

    //    // Make sure we have an element and the active element is checked.
    //    if (activeElement && activeElement.checked) {

    //        // Get all of the nodes that have the attribute.
    //        var userDefaultNodes: NodeListOf<Element> = document.querySelectorAll(`[${dataAttribute}]`);

    //        // Check all of the nodes to see if there is something checked that should not be checked now.
    //        for (var index: number = 0; index < userDefaultNodes.length; index++) {
    //            var currentInputElement: HTMLInputElement = userDefaultNodes[index] as HTMLInputElement;

    //            // If this is the same element that was clicked then skip it.
    //            if (currentInputElement && !currentInputElement.isSameNode(activeElement)) {

    //                // Allow for attributes that have more than one group set.
    //                var activeElementAttrs: linqjs.IEnumerable<string> = Enumerable.from(activeElement.getAttribute(dataAttribute).split(/\b(\s)/));
    //                var currentElementAttrs: linqjs.IEnumerable<string> = Enumerable.from(currentInputElement.getAttribute(dataAttribute).split(/\b(\s)/));

    //                // If the current element contains one of the attributes that the active element has then uncheck it.
    //                if (activeElementAttrs.any(s1 => currentElementAttrs.contains(s1))) {
    //                    currentInputElement.checked = false;
    //                }
    //            }
    //        }
    //    }
    //}

    //private functions
    private static _caret(element, position?: any) {
        var range,
            isPosition = position !== undefined;

        if (element.selectionStart !== undefined) {
            if (isPosition) {
                element.focus();
                element.setSelectionRange(position, position);
            } else {
                position = [element.selectionStart, element.selectionEnd];
            }
        } else if ((<any>document).selection) { // IE
            if (this.isVisible(element)) {
                element.focus();
            }
            range = (<any>document).selection.createRange();
            if (isPosition) {
                range.move("character", position);
                range.select();
            } else {
                var rangeElement = element.createTextRange(),
                    rangeDuplicated = rangeElement.duplicate(),
                    selectionStart, selectionEnd;

                rangeElement.moveToBookmark(range.getBookmark());
                rangeDuplicated.setEndPoint('EndToStart', rangeElement);
                selectionStart = rangeDuplicated.text.length;
                selectionEnd = selectionStart + rangeElement.text.length;

                position = [selectionStart, selectionEnd];
            }
        }

        return position;
    }



     //private _isWithinBound(input, value, bound) {
     //    var ret = true;
     //    var msg = "";

     //    //get the boundary value
     //    var b = $(input).data(bound);
     //    if (b) {
     //        b = parseFloat(b);
     //    }
     //    var bOp = $(input).data(bound + "op");

     //    //only check if we have a valid boundary and operator
     //    if (b && !isNaN(b) && bOp) {
     //        switch (bOp) {
     //            case ">":
     //                ret = value > b;
     //                msg = ret ? "" : kendo.format("{0} <= {1}", value, b);
     //                break;
     //            case ">=":
     //                ret = value >= b;
     //                msg = ret ? "" : kendo.format("{0} < {1}", value, b);
     //                break;
     //            case "<":
     //                ret = value < b;
     //                msg = ret ? "" : kendo.format("{0} >= {1}", value, b);
     //                break;
     //            case "<=":
     //                ret = value <= b;
     //                msg = ret ? "" : kendo.format("{0} > {1}", value, b);
     //                break;
     //        }
     //    }

     //    return { ret: ret, msg: msg };

     //}; //end isWithinBound()



}
